<template>
  <v-dialog v-model="dataState.isModalOpen" max-width="500">
    <v-form ref="form" v-model="isValid" @submit.prevent="save">
      <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-alert v-if="dataState.errorMessage!==null" dense type="error" class="caption mx-4">{{dataState.errorMessage}}</v-alert>
        <v-row class="pt-2 px-4 mb-0" v-if="user!=null">
          <v-col cols="6">
            <v-text-field v-model="user.lastName" :rules="formFieldRules.required" label="Фамилия" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="user.firstName" :rules="formFieldRules.required" label="Имя" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="user.secondName" label="Отчество" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="6">
            <v-select v-model="user.userProfile.gender" :rules="formFieldRules.required" :items="[{name:'Женщина', value:'female'},{name:'Мужчина', value:'male'}]" item-text="name" item-value="value" label="Пол" outlined dense hide-details="auto"/>
          </v-col>
          <v-col cols="6">
            <!-- <v-text-field v-model="user.userProfile.birth" @blur="validateBirthDate()" :rules="formFieldRules.required" v-mask="'##.##.####'" :suffix="user.userProfile.birth|dateYears" label="Дата рождения" outlined dense hide-details="auto"/> -->

            <simple-date-view :date.sync="user.userProfile.birth" title="Дата рождения" required/>
          </v-col>
          <v-col cols="12">
            <!-- <v-text-field v-model="user.phone" :rules="formFieldRules.required" label="Телефон" outlined dense hide-details="auto" v-mask="'+7 (###) ###-##-##'"/> -->
            <phone-field :phone.sync="user.phone" label="Телефон" required/>
          </v-col>
          <v-col cols="12" v-if="userType == 'admin'">
            <!-- <v-text-field v-model="user.userProfile.inn" label="ИНН" hint="12 цифр" outlined dense hide-details="auto"/> -->
            <inn-text-field :inn.sync="user.userProfile.inn" required/>
          </v-col>
          <v-col cols="12" v-if="userType == 'client'">
            <v-select v-model="user.userProfile.promoSource" :rules="formFieldRules.required" :items="promoSources" :loading="promoSourcesState.isLoading" item-text="name" item-value="id" label="Рекламный источник" outlined dense hide-details="auto" />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn text @click="$emit('update:isOpen', false)">Отмена</v-btn>
          <v-spacer/>
          <v-btn color="primary" text type="submit" :loading="dataState.isLoading" :disabled="!isValid">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import moment from "moment";
import {FormFieldRules} from "@/plugins/formFieldRules";
import InnTextField from "@/components/InnTextField";
import PhoneField from "@/components/PhoneField";
import SimpleDateView from "@/components/SimpleDateView";
import axios from "axios";

export default {
  props:['isOpen', 'userType', 'userCreateUpdate'],
  components: {InnTextField, SimpleDateView, PhoneField},
  data: () => ({
    dataState: new State(),
    formFieldRules: FormFieldRules,
    user:null,
    isValid: false,

    promoSourcesState: new State(),
    promoSources:[],
  }),
  watch:{
    isOpen(){
      if (this.isOpen){
        this.user = {
          firstName:null,
          lastName:null,
          secondName:null,
          phone:null,
          type:null,
          userProfile: {
            gender: null,
            birth: null
          }
        };
        this.user.type = this.userType;
        if (this.$refs.form!=null) this.$refs.form.resetValidation();

        if (this.userType ==='client') this.promoSourcesLoad();

        this.dataState.modalOpen();
      } else {
        this.dataState.modalClose();
      }
    },
    'user.lastName'(newValue) {
      this.user.lastName = this.$tools.nameFormatter(newValue);
    },
    'user.firstName'(newValue) {
      this.user.firstName = this.$tools.nameFormatter(newValue);
    },
    'user.secondName'(newValue) {
      this.user.secondName = this.$tools.nameFormatter(newValue);
    }
  },
  computed:{
    title(){
      if (this.userType==='client') return 'Новый клиент';
      if (this.userType==='spec') return 'Новый специалист';
      return 'Новый администратор';
    },
    pasDate: {
      get: function() {
        return moment(this.user.userProfile.pasDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(pasDate) {
        let d = moment(pasDate, 'DD.MM.YYYY')
        if (pasDate.length===10)
          if (d.isValid())
              this.user.userProfile.pasDate = d.format('YYYY-MM-DD');
      }
    },
  },
  methods:{
    save(){
      this.dataState.stateLoading();
      let v = JSON.parse(JSON.stringify(this.user));
     // v.userProfile.birth = moment(this.user.userProfile.birth, 'DD.MM.YYYY').format('YYYY-MM-DD');
      v.userProfile.birth = moment(this.user.userProfile.birth).format('YYYY-MM-DD')
      Api.service.user.create(v, ()=>{
        this.dataState.stateSuccess();
        this.dataState.modalClose();
        this.$emit('update:isOpen', false);
        this.userCreateUpdate();
      }, (e)=>{
        this.dataState.stateError(e.response.data.message);
      });
    },
    promoSourcesLoad(){
      this.promoSourcesState.stateLoading();
      axios.get(Api.host+'/user/promoSource/')
        .then(r=> {
          this.promoSources = r.data;
          this.promoSourcesState.stateSuccess();
        })
        .catch(e=>{
          this.promoSourcesState.stateError();
          console.log(e)
        });
    },
    // validateBirthDate(){
    //   let d = moment(this.user.userProfile.birth, 'DD.MM.YYYY')
    //   if (this.user.userProfile.birth.length<10 || !d.isValid())
    //     this.user.userProfile.birth = null;
    // }
  },
  // filters: {
  //   dateYears: function(date){
  //     if (date==null) return '';
  //     let d = moment(date, 'DD.MM.YYYY')
  //     if (date.length===10 && d.isValid()){
  //       return '('+d.fromNow(true)+')';
  //     }
  //     return '';
  //   },
  // },
}
</script>


