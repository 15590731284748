<template>
  <div @click.prevent="$tools.openUrl(url, typeof newWindow!=='undefined')" class="d-inline-block clickable primary--text">
    {{text}}<v-icon v-if="typeof newWindow!=='undefined'" class="pl-2" small color="primary">mdi-open-in-new</v-icon>
  </div>
</template>

<script>
export default {
  props: [
    'url',
    'text',
    'newWindow'
  ]
}
</script>
